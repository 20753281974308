import { PATHS } from 'constants/paths';

export const SERVICES_TABS = [
    {
        id: 'web-development',
        title: 'main-page.services-section.webDevelopment',
        description: 'main-page.services-section.webDevelopmentServices',
        to: `${PATHS.SERVICES}/${PATHS.WEB_DEVELOPMENT}`,
    },
    {
        id: 'mobile-development',
        title: 'main-page.services-section.mobileDevelopment',
        description: 'main-page.services-section.mobileDevelopmentServices',
        descriptionLinks: [
            {
                key: 'link',
                url: 'https://github.com/MobileReality',
                component: 'a',
            },
        ],
        to: `${PATHS.SERVICES}/${PATHS.MOBILE_DEVELOPMENT}`,
    },
    {
        id: 'ai-software',
        title: 'main-page.services-section.aiSoftware',
        description: 'main-page.services-section.aiSoftwareServices',
        to: `${PATHS.SERVICES}/${PATHS.AI_SOFTWARE}`,
    },
    {
        id: 'devops',
        title: 'main-page.services-section.devops',
        description: 'main-page.services-section.devopsServices',
        to: `${PATHS.SERVICES}/${PATHS.DEVOPS}`,
    },
];
