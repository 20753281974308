import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Button } from 'components/button-new';
import { HeaderSecond } from 'components/header-second';
import { HeaderThird } from 'components/header-third';
import { CONSTANTS } from 'constants/styles/constants';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { oneOf, string } from 'prop-types';
import { variant } from 'styles/utilities';

import { variantMediaQuery } from './utilities';

const SWrapper = styled.div`
    display: grid;
    grid-template-columns: 4fr 4fr;
    gap: 3rem;
    background-color: var(--nft-reality-black-pearl);
    align-items: center;

    ${variantMediaQuery`
        display: flex;
        flex-direction: column;
        gap: 0rem; 
    `}

    ${variant({
        default: css`
            padding: 3rem var(--section-clamp) 5rem var(--section-clamp);
        `,
        blog: css`
            margin: 2rem 0;
            padding: 3rem;
            padding: 2rem 3rem 3rem 2rem;

            ${CONSTANTS.MEDIA.max1200`
                padding: 3rem;
                padding-top: 2rem;
            `};
        `,
    })}
`;

const SRight = styled.div`
    flex: 1;
`;

const SHeader = styled.div`
    color: var(--nft-reality-white);
    font-weight: 700 !important;

    ${variant({
        default: css`
            margin-bottom: 1.5rem;
        `,
        blog: css`
            margin-bottom: 1rem;
        `,
    })}

    ${variantMediaQuery`
        text-align: center;
    `}
`;

const SHeaderAccent = styled.span`
    padding-left: 0.5rem;
    background: -webkit-linear-gradient(
        var(--nft-reality-text-gradient-start),
        var(--nft-reality-text-gradient-stop)
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;

const SDescription = styled.div`
    font-weight: 400;
    color: var(--nft-reality-main-text);

    p {
        text-align: left !important;
    }

    ${variant({
        default: css`
            margin-bottom: 3rem;
        `,

        blog: css`
            margin-bottom: 1.5rem;
            ${CONSTANTS.MEDIA.max1200`
            p {
                text-align: center !important;
            }
        `};
        `,
    })}

    ${variantMediaQuery`
        text-align: center;
    `};
`;

const SButtonWrapper = styled.div`
    display: flex;

    ${variantMediaQuery`
        justify-content: center;
        align-items: center;
        align-self: stretch; 
        width: 100%;
    `}
`;

const SButton = styled(Button)`
    display: block;
`;

export const NFTRealityBanner = ({
    h1Part1,
    h1Part2,
    description,
    variant = 'default',
}) => {
    const { formatMessage } = useIntl();
    const nftRealityImage = useStaticQuery(graphql`
        {
            image: file(
                relativePath: { eq: "nft-reality/kudos-slack-bot.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        formats: [WEBP]
                    )
                }
            }
        }
    `);

    return (
        <SWrapper {...{ variant }}>
            <GatsbyImage
                {...{ variant }}
                image={nftRealityImage.image.childImageSharp.gatsbyImageData}
                alt={formatMessage({ id: 'nft-reality-banner.alt' })}
            />
            <SRight>
                <SHeader
                    as={variant === 'default' ? HeaderSecond : HeaderThird}
                    {...{ variant }}
                >
                    <FormattedMessage id={h1Part1} />
                    <SHeaderAccent>
                        <FormattedMessage id={h1Part2} />
                    </SHeaderAccent>
                </SHeader>
                <SDescription {...{ variant }}>
                    {variant === 'default' ? (
                        <FormattedMessage id="nft-reality-banner.description" />
                    ) : (
                        documentToReactComponents(JSON.parse(description))
                    )}
                </SDescription>
                <SButtonWrapper {...{ variant }}>
                    <SButton
                        to="https://flaree.app/"
                        target="_blank"
                        component="a"
                        variant="nft-reality"
                    >
                        <FormattedMessage id="nft-reality-banner.button" />
                    </SButton>
                </SButtonWrapper>
            </SRight>
        </SWrapper>
    );
};

NFTRealityBanner.propTypes = {
    h1Part1: string.isRequired,
    h1Part2: string.isRequired,
    description: string.isRequired,
    variant: oneOf(['default', 'blog']),
};
