import React from 'react';
import { css } from 'styled-components';
import { gaTrackedEvents } from 'constants/ga-tracked-events';
import { AnimatedDevelopers } from 'modules/animated-images/developers';
import { MainSection } from 'modules/main-section';

export const HomeMainSection = () => {
    return (
        <MainSection
            icon={<AnimatedDevelopers isHero />}
            iconWrapperCss={css`
                max-width: 43rem;
            `}
            titleId="main-page.mainSection.letsSprint"
            subtitles={[
                'main-page.mainSection.weAreTheTeamOne',
                'main-page.mainSection.weAreTheTeamTwo',
            ]}
            estimateProjectAnalytics={
                gaTrackedEvents.MAIN_PAGE.CTA.ESTIMATE_PROJECT
            }
            introCallAnalytics={gaTrackedEvents.MAIN_PAGE.CTA.INTRO_CALL}
            withSocialMedia
            isMainPage
        />
    );
};
